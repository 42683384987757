.about {
  color: white;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about .box {
  width: 90%;
  height: 100px;
  background-color: black;
  border-radius: 15px;
  border: rgb(0, 225, 255) 3px solid;
  box-shadow: rgb(0, 225, 255) 1px 1px 30px 1px;
}
.about h2 {
  color: rgb(0, 225, 255);
  font-size: 2rem;
}
/* Styles next box classes and their internals */
.box {
  width: 90%;
  height: 100px;
  background-color: black;
  border-radius: 15px;
}
.box > * {
  margin: 0.5rem;
}
