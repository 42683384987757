/* width and height set in the App.js file incline with calc */
.App {
  padding-top: 6rem;
  padding-left: 10rem;
}
main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
main > div {
  padding: 6rem 0rem;
}
