.portfolio {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portfolio h2 {
  color: rgb(154, 151, 251);
  font-size: 2rem;
}
.portfolio .box {
  border: rgb(154, 151, 251) 3px solid;
  box-shadow: rgb(154, 151, 251) 1px 1px 30px 1px;
}
