.nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.nav::before {
  content: '';
  position: absolute;
  right: 0;
  top: 20px;
  bottom: 20px;
  background-color: white;
  width: 1px;
}
.nav a {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  border: 2px white solid;
  border-radius: 8px;
  width: 5rem;
  padding: 1rem;
  text-align: center;
  background-color: transparent;
  transition: background-color 0.5s;
}

.nav a::before {
  content: '';
  /* background-color: red; */
  background-image: linear-gradient(
    45deg,
    rgb(0, 225, 255),
    rgb(255, 101, 247)
  );
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  transform: translateY(102%);
  transition: transform 1s;
  border-radius: 6px;
}
.nav a:hover::before {
  transform: translateY(0%);
  transition: transform 0.5s;
}

/* Bottom right to the top right */
/* .nav a::before {
  transform: translateY(102%) translateX(102%);
  transition: transform 1s;
}
.nav a:hover::before {
  transform: translateY(0%) translateX(0%);
  transition: transform 0.5s;
} */

/* Right to Left */
/* .nav a::before {
  transform: translateX(102%);
  transition: transform 1s;
}
.nav a:hover::before {
  transform: translateX(0%);
  transition: transform 0.5s;
} */

/* Bottom to Top */
.nav a::before {
  transform: translateY(102%);
  transition: transform 1s;
}
.nav a:hover::before {
  transform: translateY(0%);
  transition: transform 0.5s;
}

a.active::before {
  transform: translateY(0%);
  transition: transform 0.5s;
}
