html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.AppDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(185, 185, 185);
}
.AppDiv h1 {
  font-family: "Noto Sans", sans-serif;
  font-size: 3.5vh;
  height: fit-content;
  width: 90%;
  word-wrap: normal;
  text-align: center;
}
.AppDiv .something {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
}
.AppDiv p {
  width: fit-content;
  font-family: "Noto Sans", sans-serif;
  font-family: "Tourney", cursive;
  font-size: 5vh;
}
@media only screen and (max-width: 700px) {
  .AppDiv h1 {
    font-weight: 900;
    height: fit-content;
  }
  .AppDiv .something {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .AppDiv .something p {
    height: fit-content;
  }
}
