.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.header::after {
  content: '';
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  height: 1px;
}
/* .header svg * {
  fill: white;
  stroke: white;
}
.header svg {
  height: 100%;
} */
.header svg path {
  fill: black;
}
.header svg {
  background-image: linear-gradient(
    45deg,
    rgb(0, 225, 255),
    rgb(255, 101, 247)
  );
  width: 10rem;
  height: auto;
  overflow: hidden;
  margin-left: 10rem;
}
