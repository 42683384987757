.resume {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.resume h2 {
  font-size: 2rem;
  color: rgb(255, 101, 247);
}
.resume .box {
  border: rgb(255, 101, 247) 3px solid;
  box-shadow: rgb(255, 101, 247) 1px 1px 30px 1px;
}
