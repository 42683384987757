.contact {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact h2 {
  color: rgb(110, 172, 252);
  font-size: 2rem;
}
.contact .box {
  border: rgb(110, 172, 252) 3px solid;
  box-shadow: rgb(110, 172, 252) 1px 1px 30px 1px;
}
